import React from 'react';

import Header from './partials/Header/Header'
import Footer from './partials/Footer/Footer'

import Home from './Home/Home'

function App() {
  return (
    <>
    <Header />
    <Home />
    <Footer />
    </>
  );
}

export default App;
