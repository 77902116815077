import React from 'react'

import { withTranslation } from 'react-i18next'

import SectionTitle from '../../components/SectionTitle/SectionTitle'

import './Team.css'

const Team = ({ t }) => {

    return (
        <section className="team container section">
            <SectionTitle title={t('teamTitle')} />
            <div className="team-items">
                {t('teamMembers').map(member => (
                    <div key={member.name} className="team-item">
                        <div className="team-item__image">
                            {member.image ?
                                <img src={member.image} alt="team member" />
                                :
                                <div className="team-item__icon">
                                    <i className="fas fa-user-alt"></i>
                                </div>
                            }
                        </div>
                        <div className="team-item__info">
                            <div className="team-item__name">
                                {member.name}
                            </div>
                            <div className="team-item__function">
                                {member.function}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default withTranslation()(Team)