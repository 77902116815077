import React from 'react'

import './SectionTitle.css'

const SectionTitle = ({title}) => {
    return (
        <h3 className="section-title">{title}</h3>
    )
}

export default SectionTitle