import React from 'react'
import { withTranslation } from 'react-i18next'

import SectionTitle from '../../components/SectionTitle/SectionTitle'

import './About.css'

const About = ({ t }) => {
    return (
        <section className="container section about" id="o-nama">
            <div className="about__image">
                <img src="/images/about.jpg" alt="about" />
            </div>
            <div className="about__info">
                <SectionTitle title={t('aboutTitle')} />
                <div className="about-text" dangerouslySetInnerHTML={{ __html: t('aboutText') }} />
            </div>
        </section >
    )
}

export default withTranslation()(About)